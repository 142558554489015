/**
 * Quote Carousel Video class
 */
class QuoteCarouselVideo {

	/**
	 * Constructor
	 * Set up and run logic
	 */
	constructor() {
		this.videoContainers = document.querySelectorAll('.block-quote-carousel--video-container');
		if (this.videoContainers.length > 0) {
			this.init();
		}
	}

	init() {
		this.videoContainers.forEach(videoContainer => {
			const video = videoContainer.querySelector('.js-quote-carousel-video');
			const muteButton = videoContainer.querySelector('.block-quote-carousel--video-mute-button');
			const carouselArrows = document.querySelectorAll('.block-quote-carousel--carousel .carousel-arrow');

			var isMuted = true;

			if(!video) {
				return;
			}

			let player = null;

			if (video) {
				player = new Vimeo.Player(video);

				player.setVolume(0);
				videoContainer.classList.add('is-playing');
				player.play();
			}

			if(carouselArrows){
				carouselArrows.forEach((arrow) => {
					arrow.addEventListener('click', (e) => {
						player.setVolume(0);
						player.play();
						isMuted = true;
						muteButton.classList.remove('active');
					})
				})
			}

			muteButton.addEventListener('click', (e) => {
				if(isMuted){
					player.setVolume(1);
					isMuted = false;
					muteButton.classList.add('active');
				} else {
					player.setVolume(0);
					isMuted = true;
					muteButton.classList.remove('active');
				}
			});

			// listen for slide change
			document.addEventListener('quoteCarouselSlideChange', () => {
				player.setVolume(0);
				player.play();
				isMuted = true;
				muteButton.classList.remove('active');
			});
		});
	}
}
new QuoteCarouselVideo();
